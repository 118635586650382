@import url(https://fonts.googleapis.com/css2?family=Merriweather+Sans&family=Open+Sans:wght@300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  overflow: hidden;
}



.partOne {
    height: 70vh;
}

.partOne {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.partOne .leftSide {
    flex : 1.5 1;
}
.partOne .leftSide img {
    height: auto;
    width: 70%;
}
.partOne .rightSide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 2 1;
    grid-gap: 2rem;
    gap: 2rem;

}

.partOne .rightSide .mainText {
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    line-height: 1rem;
}

.partOne .rightSide .mainText h4 {
    font-size: 1.2rem;
    font-weight: 500;
}
.partOne .rightSide .mainText a {
    font-weight: 600;
}

.contactButton {
    position: relative;
}
.contactButton button{

    padding: 1rem 2rem;
    background-color: #ffe79d;
    font-size: 1.2rem;
    border-radius: 5px;
    outline: none;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    box-shadow: 0 0 4px 2px #EFEFEF;

}

.contactButton button:hover {
    cursor: pointer;
}

.contactButton .infoBulle {
    position: absolute;
    background-color: white;
    bottom: -24px;
    box-shadow: 0 0 4px 2px #efefef;
    padding: 4px 12px;
    right: -57px;

}

.switchingText{
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 4rem;
    color: #222222;
    font-weight: bold;
}

@media screen and (max-width: 600px ) {
    .partOne {
        flex-direction: column;
        height:65vh;
        align-items: center;
    }

    .partOne .leftSide {
        flex: unset;
    }

    .partOne .leftSide img {
        height: auto;
        width: 60%;
        border-bottom: 1px solid #222222;
    }

    .partOne .rightSide {
        align-items: center;
    }

    .partOne .rightSide .mainText {
        line-height: 2rem;
        width: 90%;
        text-align: center;
    }

    .partOne .rightSide .mainText > h1 {
        display: none;
    }

    .switchingText {
        font-size: 2rem;
    }
}
.partTwo {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headTitle h1 {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 4rem;
    color: #222222;
    font-weight: bold;
}

.headTitle h4 {
    font-family: 'Open Sans', sans-serif;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.listOfBox {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    width: 60%;
    grid-gap: 3rem;
    grid-template-rows: repeat(2, minmax(14rem, 1fr));
    padding-bottom: 2rem;
}

.boxOfCompliance {
    box-shadow: 0 0 4px 2px #F2F2F2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 5px;
    background-color: #EAEAEA;
}

.boxOfCompliance h3 {

    font-family: 'Merriweather Sans', sans-serif;
    font-size: 1.5rem;
    color: #222222;
    font-weight: bold;
}

.boxOfCompliance p {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: justify;
}

@media screen and (max-width: 600px ) {

    .headTitle {
        width:90%;
    }
    .headTitle h1 {
        font-size: 2rem;
    }

    .listOfBox {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
        width: 90%;
    }
}

.footer {
    width: auto;
    height: auto;
    padding: 2rem;
    background-color: #222222;
    display: flex;
    justify-content: center;
    color: white;
    flex-direction: column;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.headContent {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.headContent > h1 {
    font-size: 5rem;
    margin: 0;
}

.headContent >h5 {
    margin: 0;
    letter-spacing: 0.15rem;
    font-weight: 400;
}

.mainContent {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 1rem;
    gap: 1rem;
}

.mainContent  h5, .mainContent h2, .mainContent h3 {
    margin: 0
}

.mainContent h5 {
    font-weight: 400;
}

.listOfPerks {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap:1rem;
    gap:1rem;
}

.perk {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.perk > img {
    width: 30px;
    fill: white;
}

.mainContent > h4 {
    float: right
}

@media screen and (max-width: 600px ) {

    .headContent {
        align-items: center;
        width: 95%;
    }

    .headContent h1 {
        font-size: 2rem;
    }

    .mainContent {
        width: 95%;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }
    .perk {
        flex-direction: column;
        grid-gap: 0;
        gap: 0;
    }

    .perk > img {
        display: none;
    }

}
