
.footer {
    width: auto;
    height: auto;
    padding: 2rem;
    background-color: #222222;
    display: flex;
    justify-content: center;
    color: white;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.headContent {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.headContent > h1 {
    font-size: 5rem;
    margin: 0;
}

.headContent >h5 {
    margin: 0;
    letter-spacing: 0.15rem;
    font-weight: 400;
}

.mainContent {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.mainContent  h5, .mainContent h2, .mainContent h3 {
    margin: 0
}

.mainContent h5 {
    font-weight: 400;
}

.listOfPerks {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:1rem;
}

.perk {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.perk > img {
    width: 30px;
    fill: white;
}

.mainContent > h4 {
    float: right
}

@media screen and (max-width: 600px ) {

    .headContent {
        align-items: center;
        width: 95%;
    }

    .headContent h1 {
        font-size: 2rem;
    }

    .mainContent {
        width: 95%;
        gap: 0.5rem;
    }
    .perk {
        flex-direction: column;
        gap: 0;
    }

    .perk > img {
        display: none;
    }

}