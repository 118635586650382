.partTwo {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.headTitle h1 {
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 4rem;
    color: #222222;
    font-weight: bold;
}

.headTitle h4 {
    font-family: 'Open Sans', sans-serif;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.listOfBox {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    width: 60%;
    grid-gap: 3rem;
    grid-template-rows: repeat(2, minmax(14rem, 1fr));
    padding-bottom: 2rem;
}

.boxOfCompliance {
    box-shadow: 0 0 4px 2px #F2F2F2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 5px;
    background-color: #EAEAEA;
}

.boxOfCompliance h3 {

    font-family: 'Merriweather Sans', sans-serif;
    font-size: 1.5rem;
    color: #222222;
    font-weight: bold;
}

.boxOfCompliance p {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: justify;
}

@media screen and (max-width: 600px ) {

    .headTitle {
        width:90%;
    }
    .headTitle h1 {
        font-size: 2rem;
    }

    .listOfBox {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
        width: 90%;
    }
}