

.partOne {
    height: 70vh;
}

.partOne {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.partOne .leftSide {
    flex : 1.5;
}
.partOne .leftSide img {
    height: auto;
    width: 70%;
}
.partOne .rightSide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 2;
    gap: 2rem;

}

.partOne .rightSide .mainText {
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    line-height: 1rem;
}

.partOne .rightSide .mainText h4 {
    font-size: 1.2rem;
    font-weight: 500;
}
.partOne .rightSide .mainText a {
    font-weight: 600;
}

.contactButton {
    position: relative;
}
.contactButton button{

    padding: 1rem 2rem;
    background-color: #ffe79d;
    font-size: 1.2rem;
    border-radius: 5px;
    outline: none;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    -webkit-box-shadow: 0 0 4px 2px #EFEFEF;
    box-shadow: 0 0 4px 2px #EFEFEF;

}

.contactButton button:hover {
    cursor: pointer;
}

.contactButton .infoBulle {
    position: absolute;
    background-color: white;
    bottom: -24px;
    box-shadow: 0 0 4px 2px #efefef;
    padding: 4px 12px;
    right: -57px;

}

.switchingText{
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 4rem;
    color: #222222;
    font-weight: bold;
}

@media screen and (max-width: 600px ) {
    .partOne {
        flex-direction: column;
        height:65vh;
        align-items: center;
    }

    .partOne .leftSide {
        flex: unset;
    }

    .partOne .leftSide img {
        height: auto;
        width: 60%;
        border-bottom: 1px solid #222222;
    }

    .partOne .rightSide {
        align-items: center;
    }

    .partOne .rightSide .mainText {
        line-height: 2rem;
        width: 90%;
        text-align: center;
    }

    .partOne .rightSide .mainText > h1 {
        display: none;
    }

    .switchingText {
        font-size: 2rem;
    }
}